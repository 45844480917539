
#EditDashboard.BaseView {
	display: flex;
	#editorContainer {
		flex-grow: 1;
		border-right: 1px solid #aaa;
		width: 100% !important;
        height: 100% !important;
	}
	#SidePanel {
		width: 300px;
		height: 100%;
		padding: 10px;
		.Info {
			font-size: 12px;
			text-align: center;
			display: block;
			color: #666;
			margin-bottom: 20px;
		}
		button {
			display: block;
			width: 100%;
			margin-bottom: 10px;
		}
		.AgentList {
			margin-top: 30px;
			h3 {
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 4px;
				border-bottom: 1px solid #ccc;
				padding-bottom: 4px;
			}
			.Agent {
				font-size: 14px;
				font-weight: normal;
				padding: 2px 0;
				margin-bottom: 4px;
				.Options {
					float: right;
					padding-right: 30px;
				}
			}
		}
	}
}
