
@import './reset.css';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=cyrillic');

$fa-font-path: './fontawesome/webfonts';
@import './fontawesome/scss/fontawesome.scss';
@import './fontawesome/scss/regular.scss';
@import './fontawesome/scss/solid.scss';

html, body, #app {
	height: 100%;
}

body {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	overflow-y: scroll;
	background: #eee;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
}

#app {
	display: flex;
	flex-direction: column;
	.BaseView {
		flex-grow: 1;
	}
	#BlockingMessage {
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		.Content {
			background: white;
			padding: 15px;
			border-radius: 6px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		}
	}
}

// Components
// ...

// Views
@import '../views/dashboards.scss';
@import '../views/edit-dashboard.scss';
@import '../views/login.scss';
