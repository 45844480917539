
#Dashboards.BaseView {

	.ButtonPanelList {
		padding: 10px;
		display: flex;
		flex-wrap: wrap;
		.ButtonCard {
			a {
				display: block;
				height: 100%;
			}
			.bp3-card {
				margin: 15px;
				.Container {
					text-align: center;
					width: 200px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 150px;
					.Icon {

					}
					.Title {
						font-size: 18px;
						padding: 10px 0;
						line-height: 22px;
					}
					.Info {
						color: #888;
						font-size: 14px;
					}
				}
			}
		}
	}
}
