
#Login.BaseView {

	display: flex;
	justify-content: center;
	align-items: center;

	.LoginPanel {
		flex-grow: 0;
		flex-shrink: 1;
		width: 300px;
		height: auto;
		background: white;
		padding: 10px;
		border-radius: 6px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		h3 {
			text-align: center;
			padding: 10px 0 20px;
			font-size: 18px;
		}
		.Buttons {
			margin-top: 10px;
			text-align: right;
			display: flex;
			align-items: center;
			justify-content: space-between;
			a {
				font-size: 12px;
			}
		}
	}

}
